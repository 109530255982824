// EVENTLISTENERS
document.getElementById("mobile-nav").addEventListener("click", toggleNavigation, false);
document.getElementById("dgi-support-form-reset").addEventListener("click", resetForm, false);

// TOGGLE MOBILE NAV
function toggleNavigation () {
    document.body.classList.toggle("menu__open");
    document.getElementById("mobile-nav").classList.toggle("open");
}

// VALIDATION
$(document).ready(function () {
    $('#dgi-support-form').validate({
        ignore: ":hidden:not(#regarding-value)",
        rules: {
            name: {
                required: true
            },
            email: {
                required: true,
                email: true
            },
            regarding: {
                required: true
            },
            comments: {
                required: true
            },
            customfile: {
                required: false,
                accept: "image/*,application/pdf"
            }
        },
        messages: {
            name: 'Navn skal udfyldes',
            email: 'Email feltet indeholder ikke en gyldig email adresse',
            regarding: 'Vælg venligst hvad din forespørgsel drejer sig om',
            comments: 'Uddybende kommentar skal udfyldes',
            customfile: 'Vælg korrekt fil format'
        },
        errorClass: "invalid-feedback",
        success: function (element) {
            // REMOVE ELEMENT WHEN RULE IS VALID
            element.remove();
        },
        submitHandler: function(form) {
            // MAKE AJAX CALL AND SEND INPUT DATA TO SERVICEDESK API
            event.preventDefault();

            // GET FORM DATA
            const supportFormData = formToJSON(form.elements);
            
            $.ajax({
                url: '/Umbraco/Api/Formular/OpretForespoergsel',
                type: 'POST',
                dataType: 'json',
                data: {
                    Forespoerger: {
                        Navn: supportFormData.name,
                        Email: supportFormData.email
                    },
                    Emne: 'Support.dgi.dk: ' + supportFormData.regarding,
                    Beskrivelse: supportFormData.comments,
                    Gruppe: supportFormData.servicedeskgroup
                },
                beforeSend: function () {
                    // DISABLE SUBMIT TO PREVENT MULTIPLE CLICKS
                    $('#dgi-support-form-submit').prop('disabled', true);
                },
                success: function (response) {
                    if (response && response.Error === true) {
                        console.error(response.ErrorMessage);
                        alert('Der skete en fejl! Kunne ikke oprette din forespørgsel.');
                    }

                    // RESET FORM AFTER SUBMIT
                    resetForm();
                    // ENABLE SUBMIT
                    $('#dgi-support-form-submit').prop('disabled', false);
                },
                error: function (response) {
                    console.error(response.ErrorMessage);
                }
            });
        }
    });
});

// RESET FORM
function resetForm () {
    // PREVENT FORM FROM SUBMITTING
    event.preventDefault();

    // REST FORM VALUES AND SET DEFAULT TEXT
    document.getElementById("dgi-support-form").reset();
    $('#dropdown-regarding').text('Vælg din forespørgsel');
    $(".custom-file-label").attr('data-content', '');
    $(".custom-file-label").text('Vælg fil');

    // RESET VALIDATION FIELDS
    $('#dgi-support-form').validate().resetForm();
}

// FILE UPLOAD
$("input[type=file]").change(function () {
    var fieldVal = $(this).val();
    // Change the node's value by removing the fake path (Chrome)
    fieldVal = fieldVal.replace("C:\\fakepath\\", "");
    if (fieldVal != undefined || fieldVal != "") {
        $(this).next(".custom-file-label").attr('data-content', fieldVal);
        $(this).next(".custom-file-label").text(fieldVal);
    }
});

// GENERAL FUNCTIONS
window.setRegardingValue = function (oRegarding) {
    var sRegarding = $(oRegarding).attr('value');
    var sServiceDeskGroup = $(oRegarding).attr('group');
    
    $('#dropdown-regarding').text(sRegarding);
    $('#regarding-value').val(sRegarding);
    $('#regarding-group').val(sServiceDeskGroup);

    $('#regarding-value').valid();
};

const formToJSON = elements => [].reduce.call(elements, (data, element) => {
    // MAKE SURE THE ELEMENT HAS REQUIRED PROPERTIES
    if (isValidElement(element)) {
        data[element.name] = element.value;
    }

    return data;
}, {});

// CHECK IF ELEMENT IS VALID
const isValidElement = element => {
    return element.name && element.value;
};

// 
console.log('utils.js is now loaded...');